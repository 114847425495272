import { z } from 'zod';
import { logger } from '@repo/utils';

let config: AppConfigSchema;

export function getEnv() {
  if (typeof process !== 'undefined' && process.env) {
    // Node environment
    return process.env;
  } else if (typeof globalThis !== 'undefined' && (globalThis as any)?.env) {
    // Cloudflare Pages/Workers environment
    return (globalThis as any).env;
  }
  return {}; // Default empty object if no environment is found
}

function getEnvVar(variableName: string): string | undefined {
  let envVars = getEnv();

  if (variableName === 'USE_NODE_CACHE') {
    // when debugging locally we want to use the node cache
    // when using cloudflare pages we want to use the upstash cache
    return !!(globalThis as any)?.env ? 'false' : 'true';
  }

  const branch = envVars.CF_PAGES_BRANCH || envVars.BRANCH || '';

  let branchPrefix = '';

  switch (branch) {
    case 'main':
      branchPrefix = 'PROD_';
      break;
    case 'staging':
      branchPrefix = 'STAGING_';
      break;
    case 'dev':
      branchPrefix = 'DEV_';
      break;
    case 'local':
      branchPrefix = 'LOCAL_';
      break;
    default:
      if (branch.startsWith('feature/')) {
        branchPrefix = 'FEATURE_';
      }
  }

  if (variableName === 'BRANCH') {
    if (!branchPrefix) {
      return branch;
    }
    if (branch === 'main') {
      // This will only be used in the app to display the branch name as a label on the site
      // if this is prod we don't want to show any branch labels
      return '';
    }

    return branchPrefix.replace('_', '');
  }

  const branchSpecificVar = branchPrefix + variableName;

  // Try to get the branch-specific value first
  return envVars[branchSpecificVar] || envVars[variableName] || undefined;
}

const EnvSchema = z.object({
  SENTRY_DSN: z.string().optional(),
  DB_NAME: z.string().optional(),
  DB_HOST: z.string().optional(),
  DB_PORT: z.coerce
    .number({
      description:
        '.env files convert numbers to strings, therefore we have to enforce them to be numbers'
    })
    .positive()
    .max(65536, `options.port should be >= 0 and < 65536`)
    .optional(),
  DB_USER: z.string().optional(),
  DB_PASSWORD: z.string().optional(),
  LOG_LEVEL: z.string().default('info'),
  PORT: z.coerce
    .number({
      description:
        '.env files convert numbers to strings, therefore we have to enforce them to be numbers'
    })
    .positive()
    .max(65536, `options.port should be >= 0 and < 65536`)
    .default(3030)
    .optional(),
  REDIS_HOST: z.string().optional(),
  REDIS_PASSWORD: z.string().optional(),
  REDIS_PORT: z.coerce
    .number({
      description:
        '.env files convert numbers to strings, therefore we have to enforce them to be numbers'
    })
    .positive()
    .max(65536, `options.port should be >= 0 and < 65536`)
    .default(6379)
    .optional(),
  S3_ACCESS_KEY_ID: z.string().optional(),
  S3_SECRET_ACCESS_KEY: z.string().optional(),
  S3_BUCKET_NAME: z.string().optional(),
  S3_ENDPOINT: z.string().optional(),
  S3_REGION: z.string().optional(),
  SYSADMIN_EMAILS: z.string().optional(),
  CACHE_DISABLED: z.coerce.boolean().optional(),
  USE_NODE_CACHE: z.coerce.boolean().optional(),
  DB_NO_SSL: z.coerce.boolean().optional()
});

export type AppConfigSchema = z.infer<typeof EnvSchema>;

export function getAppConfig(): AppConfigSchema {
  if (!config) {
    config = {
      ...EnvSchema.parse({
        NODE_ENV: getEnvVar('NODE_ENV'),
        SENTRY_DSN: getEnvVar('SENTRY_DSN'),
        DB_NAME: getEnvVar('DB_NAME'),
        DB_HOST: getEnvVar('DB_HOST'),
        DB_PORT: getEnvVar('DB_PORT'),
        DB_USER: getEnvVar('DB_USER'),
        DB_PASSWORD: getEnvVar('DB_PASSWORD'),
        LOG_LEVEL: getEnvVar('LOG_LEVEL'),
        PORT: getEnvVar('PORT'),
        REDIS_HOST: getEnvVar('REDIS_HOST'),
        REDIS_PORT: getEnvVar('REDIS_PORT'),
        REDIS_PASSWORD: getEnvVar('REDIS_AUTH'),
        S3_ACCESS_KEY_ID: getEnvVar('S3_ACCESS_KEY_ID'),
        S3_SECRET_ACCESS_KEY: getEnvVar('S3_SECRET_ACCESS_KEY'),
        S3_BUCKET_NAME: getEnvVar('S3_BUCKET_NAME'),
        S3_ENDPOINT: getEnvVar('S3_ENDPOINT'),
        S3_REGION: getEnvVar('S3_REGION'),
        SYSADMIN_EMAILS: getEnvVar('SYSADMIN_EMAILS'),
        CACHE_DISABLED: getEnvVar('CACHE_DISABLED'),
        USE_NODE_CACHE: getEnvVar('USE_NODE_CACHE'),
        DB_NO_SSL: getEnvVar('USE_NODE_CACHE')
      }),
      ...{ BRANCH: getEnvVar('BRANCH') as string }
    };
    logger.init(config.LOG_LEVEL);
  }
  return config;
}
