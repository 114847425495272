export function trimString(text: string | null | undefined): string {
  if (typeof text !== 'string') {
    return '';
  }
  return (text || '').trim();
}

export function formatEmail(email: string): string {
  email = trimString(email);
  return email.toLowerCase();
}

export function createDisplayName(firstName: string, lastName: string): string {
  firstName = trimString(firstName);
  lastName = trimString(lastName);

  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }

  return firstName || lastName || '';
}

export function buildUrl(host: string, path: string) {
  host = trimString(host);
  path = trimString(path);

  // make sure that there is only one slash between the host and the path
  if (host.endsWith('/') && path.startsWith('/')) {
    return `${host}${path.slice(1)}`;
  }
  // make sure that there is a slash between the host and the path
  if (!host.endsWith('/') && !path.startsWith('/')) {
    return `${host}/${path}`;
  }

  return `${host}${path}`;
}
